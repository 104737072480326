import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import style from './ConsultingComponent.module.scss'
/* import Consulting from './components/Consulting' */
import { injectIntl } from 'react-intl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import I18nLink from '../../i18n/Link'
class ConsultingComponent extends React.Component {
  static propTypes = {
    images: PropTypes.array,
    data: PropTypes.object,
    intl: PropTypes.object,
  }

  state = {
    heading: this.props.intl.messages.consultingPage.heading,
    consultingText: this.props.intl.messages.consultingPage.consultingText,
  }

  render() {
    const { heading, consultingText } = this.state
    return (
      <React.Fragment>
        <div className={classnames(style.consultingPage, style.container, style.basicBlock)}>
          <div className={classnames(style.consultingContainer, style.container)}>
            <h1 dangerouslySetInnerHTML={{ __html: heading }} />
            <div className={classnames(style.consultingText, style.innerContainer)}>
              <h3 dangerouslySetInnerHTML={{ __html: consultingText.heading }} />
              <ul>
                {consultingText.list.map((x, i) => (
                  <li key={`consult-list-${i}`}>
                    <FontAwesomeIcon size="lg" icon={[x.icon.class, x.icon.name]} />
                    <p dangerouslySetInnerHTML={{ __html: x.label }} />
                  </li>
                ))}
              </ul>
              <I18nLink to="/#contact">
                <button dangerouslySetInnerHTML={{ __html: consultingText.contactBtn }} />
              </I18nLink>
            </div>
            {/* <Consulting images={this.props.images} /> */}
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default injectIntl(ConsultingComponent)
